import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { UserProfile } from '../models/profile';
import { routePaths } from '../utils/route-paths';

import { AppConfigService } from './app-config.service';
import { CurrentUserService } from './current-user.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { AuthDto } from './mappers/dto/auth-dto';
import { ResetPasswordDto } from './mappers/dto/reset-password-dto';
import { LoginMapper } from './mappers/login.mapper';
import { NewPasswordMapper } from './mappers/new-password.mapper';

/**
 * Data to pass into login method.
 */
export interface LoginData {
  /** User email. */
  email: string;
  /** User password. */
  password: string;
}

/** Data shape for form */
export interface ResetPasswordData {
  /** Email for resetting password */
  email: string;
}

/** Data shape for new-password form */
export interface NewPasswordData {
  /** New password */
  password: string;
  /** Confirmation of new password */
  confirmPassword: string;
}

/** Data shape for new-password request */
export interface NewPasswordRequestData {
  /** New password */
  password: string;
  /** Confirmation of new password */
  confirmPassword: string;
  /** User id */
  uid: string;
  /** User token for password reset */
  token: string;
}

/**
 * Perform auth operations.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly loginUrl = new URL('auth/login/', this.appConfig.apiUrl).toString();
  private readonly logoutUrl = new URL('auth/logout/', this.appConfig.apiUrl).toString();
  private readonly resetPasswordUrl = new URL(
    'auth/password/reset/',
    this.appConfig.apiUrl,
  ).toString();
  private readonly resetPasswordConfirmUrl = new URL(
    'auth/password/reset/confirm/',
    this.appConfig.apiUrl,
  ).toString();

  public constructor(
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    private readonly loginMapper: LoginMapper,
    private readonly newPasswordMapper: NewPasswordMapper,
    private readonly appConfig: AppConfigService,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly currentUserService: CurrentUserService,
  ) {
  }

  /**
   * Login a user.
   * @param data Login data.
   */
  public login(data: LoginData): Observable<UserProfile> {
    const body = this.loginMapper.toDto(data);
    return this.httpClient.post<AuthDto>(this.loginUrl, body).pipe(
      switchMap(authDto => this.currentUserService.completeAuthorizationProcess(authDto)),
      catchError((httpError: HttpErrorResponse) => {
        const apiError = this.appErrorMapper.fromDtoWithValidationSupport(
          httpError,
          this.loginMapper,
        );
        return throwError(apiError);
      }),
    );
  }

  /**
   * Reset user password
   * @param data Reset password data.
   */
  public resetPassword(data: ResetPasswordData): Observable<string> {
    return this.httpClient.post<ResetPasswordDto>(this.resetPasswordUrl, data).pipe(
      map(response => response.detail),
      catchError((httpError: HttpErrorResponse) => {
        const apiError = this.appErrorMapper.fromDtoWithValidationSupport(
          httpError,
          this.loginMapper,
        );
        return throwError(apiError);
      }),
    );
  }

  /**
   * Reset user password confirm
   * @param data New password request data.
   */
  public resetPasswordConfirm(data: NewPasswordRequestData): Observable<string> {
    const body = this.newPasswordMapper.toDto(data);
    return this.httpClient.post<ResetPasswordDto>(this.resetPasswordConfirmUrl, body).pipe(
      map(response => response.detail),
      catchError((httpError: HttpErrorResponse) => {
        const apiError = this.appErrorMapper.fromDtoWithValidationSupport(
          httpError,
          this.newPasswordMapper,
        );
        return throwError(apiError);
      }),
    );
  }

  /**
   * Logout current user.
   */
  public logout(): Observable<void> {
    return this.httpClient.post<void>(this.logoutUrl, null).pipe(
      tap(() => this.router.navigate(routePaths.login)),
      switchMapTo(this.currentUserService.logout()),
      catchError((httpError: HttpErrorResponse) => {
        const apiError = this.appErrorMapper.fromDtoWithValidationSupport(
          httpError,
          this.loginMapper,
        );
        return throwError(apiError);
      }),
    );
  }
}
